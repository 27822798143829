import React from 'react'
import { getImage, getSrc } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import PageLayout from '../layouts/PageLayout'
import Seo from 'components/Seo'
import Section from 'components/Section/Section'

const ArticleData = loadable(() =>
  import('../components/StructuredData/ArticleData')
)
const BlogPost = loadable(() => import('../components/BlogPost'))

const BlogTemplate = ({
  data: {
    markdownRemark: {
      body,
      timeToRead,
      frontmatter: { title, author, date, rawDate, description, headerImage },
    },
  },
  location: { pathname },
}) => {
  // TODO: create image component that handles these helper functions
  const image = getImage(headerImage)
  const imagePath = getSrc(headerImage)

  return (
    <PageLayout pathname={pathname}>
      <Seo
        title={`${title} | Iliad Epic Grow`}
        description={description}
        ogImage={imagePath}
        pathname={pathname}
        article
      />
      <ArticleData
        title={title}
        description={description}
        author={author}
        date={rawDate}
        articleDataImage={imagePath}
        pathname={pathname}
      />
      <Section as='article' id={`page-section-0`} colorTheme='lace'>
        <BlogPost
          title={title}
          author={author}
          date={date}
          timeToRead={timeToRead}
          headerImage={image}
          body={body}
          pathname={pathname}
        />
      </Section>
    </PageLayout>
  )
}

export default BlogTemplate

export const BlogTemplateQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      body: html
      timeToRead
      frontmatter {
        title
        author
        rawDate: date
        date(formatString: "MMMM Do, YYYY")
        description
        headerImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
